import React from "react"

import { Footer, Header, Layout, Sections, SEO } from "components"

import Container from "components/Layout/styles/Container"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header />
    <Container>
      <main>
        <Sections />
      </main>
    </Container>
    <Footer />
  </Layout>
)

export default IndexPage
